// export const API_BASE_URL = 'http://192.168.29.54:4000'
export const API_BASE_URL = 'https:///api.zanride.com'
export const MEDIA_URL = 'https://zan-rides-assets.s3.af-south-1.amazonaws.com'
export const GOOGLE_API_KEY = 'AIzaSyBydxmR64ODpWEtho3aPfVb-f6yvSy7uxY'

export const ApiEndpoints = {
  AUTH: {
    register: `${API_BASE_URL}/api/v1/auth/register/customer`,
    login: `${API_BASE_URL}/api/v1/auth/login/customer`,
    me: `${API_BASE_URL}/api/v1/auth/me`,
    create_mpin: `${API_BASE_URL}/api/v1/auth/create/mpin`,
    check_mpin: `${API_BASE_URL}/api/v1/auth/check/mpin`,
    forgot_mpin: `${API_BASE_URL}/api/v1/auth/forgot/mpin`,
    reset_mpin: `${API_BASE_URL}/api/v1/auth/reset/mpin`,
    check_referral_code: `${API_BASE_URL}/api/v1/misc/check/referal_code/customer`,
    logout: `${API_BASE_URL}/api/v1/auth/logout`
  },
  SOCIAL_AUTH: {
    google_login: `${API_BASE_URL}/api/v1/social/auth/google/login/customer`,
    google_signup: `${API_BASE_URL}/api/v1/social/auth/google/register/customer`,
    apple_login: `${API_BASE_URL}/api/v1/social/auth/apple/login/customer`
  },
  OTP: {
    verifyotp: type => `${API_BASE_URL}/api/v1/auth/verify/otp/${type}`,
    resendotp: type => `${API_BASE_URL}/api/v1/auth/resend/otp/${type}`
  },
  PROFILE: {
    update_profile: `${API_BASE_URL}/api/v1/auth`,
    get_rating: user_id => `${API_BASE_URL}/api/v1/ratings/${user_id}`
  },
  LEGAL_CONTENT: {
    privacy_policy: `${API_BASE_URL}/api/v1/misc/legalcontent/privacy_policy`,
    terms_and_condition: `${API_BASE_URL}/api/v1/misc/legalcontent/terms_and_conditions`
  },
  SAVED_LOCATION: {
    saved_locations: `${API_BASE_URL}/api/v1/profile/address`,
    change_locations: type => `${API_BASE_URL}/api/v1/profile/address/${type}`
  },
  POPULAR_LOCATION: {
    get_popular_location: `${API_BASE_URL}/api/v1/misc/google/places`
  },
  EMERGENCY_CONTACT: {
    emergency_contact: `${API_BASE_URL}/api/v1/emergency/contact`,
    delete_emergency_contact: id => `${API_BASE_URL}/api/v1/emergency/contact/${id}`,
    verify_otp: `${API_BASE_URL}/api/v1/emergency/contact/verify/otp`
  },
  BOOK_RIDE: {
    book_ride: `${API_BASE_URL}/api/v1/rides`,
    // search_drivers: id => `${API_BASE_URL}/api/v1/customer/search/drivers/${id}`,
    // find_vehicle_type: ride_id => `${API_BASE_URL}/api/v1/customer/available/vehicle/${ride_id}`,
    find_vehicle_category: ride_id => `${API_BASE_URL}/api/v1/customer/available/categories/${ride_id}`
  },
  RIDES: {
    get_rides: ride_id => `${API_BASE_URL}/api/v1/rides/${ride_id}`,
    cancel_ride: ride_id => `${API_BASE_URL}/api/v1/rides/${ride_id}`
  },
  DRIVER: {
    select_driver: rideId => `${API_BASE_URL}/api/v1/customer/select/drivers/${rideId}`,
    all_driver_location: `${API_BASE_URL}/api/v1/customer/dashboard`
  },
  ORDER_DATA: {
    hub_address: `${API_BASE_URL}/api/v1/misc/hubaddress`,
    vehicle_type: `${API_BASE_URL}/api/v1/customer/check/vehicleType`,
    size_chart: `${API_BASE_URL}/api/v1/misc/sizecharts`,
    vehicle_list: `${API_BASE_URL}/api/v1/misc/vehicle/types`,
    dropoff_locations: `${API_BASE_URL}/api/v1/misc/drop_locations`
  },
  CATEGORIES: {
    categories: type => `${API_BASE_URL}/api/v1/misc/categories/${type}`,
    product_category: type => `${API_BASE_URL}/api/v1/misc//categories/product/${type}`
  },
  BUSINESS_ACCOUNT: {
    create_business_account: `${API_BASE_URL}/api/v1/business`
  },
  NOTIFICATION: {
    notification: `${API_BASE_URL}/api/v1/notifications`
  },
  WALLET: {
    view_wallet: `${API_BASE_URL}/api/v1/wallets`,
    search_user: `${API_BASE_URL}/api/v1/wallets/select_user/customer`,
    payment: ride_id => `${API_BASE_URL}/api/v1/wallets/payment/${ride_id}`,
    tip_payment: ride_id => `${API_BASE_URL}/api/v1/wallets/tip/payment/${ride_id}`,
    transfer_funds: user_id => `${API_BASE_URL}/api/v1/wallets/transfer/${user_id}`
  },
  FEEDBACK: {
    feedbacks: `${API_BASE_URL}/api/v1/misc/feedbacks/customer`
  },
  COUPON: {
    get_coupons: `${API_BASE_URL}/api/v1/misc/coupons`,
    redeem_coupons: ride_id => `${API_BASE_URL}/api/v1/customer/coupons/redeem/${ride_id}`
  },
  PAYMENT: {
    verify_payment: ride_id => `${API_BASE_URL}/api/v1/payments/fluterwave/webhook/ride/${ride_id}`,
    wallet: `${API_BASE_URL}/api/v1/payments/fluterwave/webhook/wallet`,
    history: `${API_BASE_URL}/api/v1/payments/history`,
    verify_tip_payment: ride_id => `${API_BASE_URL}/api/v1/payments/fluterwave/webhook/tips/${ride_id}`
  },
  ACTIVITY: {
    download_invoice: ride_id => `${API_BASE_URL}/api/v1/rides/download/${ride_id}`
  },
  REFER_FRIEND: {
    refer_friend_info: `${API_BASE_URL}/api/v1/misc/refer_friends/customer`
  }
}
