// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  signOut,
  reauthenticateWithPopup,
  FacebookAuthProvider
} from 'firebase/auth'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { Email } from 'mdi-material-ui'
import Message from 'src/views/notifications/Message'
import { toast } from 'react-toastify'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import { useAuth } from 'src/hooks/useAuth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: 'AIzaSyDoXRrS5NKxXzTRjXk8IlP5W-CTM1vdEN0',
  authDomain: 'zenride-customer.firebaseapp.com',
  projectId: 'zenride-customer',
  storageBucket: 'zenride-customer.firebasestorage.app',
  messagingSenderId: '242121909120',
  appId: '1:242121909120:web:5b3301955ec4cf773c9765',
  measurementId: 'G-PF9NWPHHPT',
  clientId: 'com.zanRides.ZanRides',
  redirectUri: 'https://zenride-customer.firebaseapp.com/__/auth/handler'
}
const VAPID_KEY = 'BDrq4h-J9SzHDNoEDwhlxdxDnUR8p31n50XJsHhQJAbJSjDvccvRT2ejvq1zAre44nwktbeigIEA54hANcLfeXw'

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const firebaseApp = initializeApp(firebaseConfig)
const provider = new GoogleAuthProvider()
provider.setCustomParameters({
  prompt: 'select_account '
})
export const auth = getAuth()
// signOut(auth)

export const signInWithGooglePopup = () => signInWithPopup(auth, provider)

const appleProvider = new OAuthProvider('apple.com')
appleProvider.addScope('email')
appleProvider.setCustomParameters({
  clientId: 'com.zanRideCustomer.zanRide',
  teamId: '6499216655',
  keyId: 'TQ6TVKHX79',
  privateKey:
    'MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgDd882NM+cytnBC3MFmToWfgyn+rZYlPEfm/GkdTHe+OgCgYIKoZIzj0DAQehRANCAARN1zmY1CJ1SMAEltuzNIlMRVxrtH+n8svD0l4j/N6ni2KFLI003XeqPlQhAachyjCYDYxUpoEHyKfKhpWjjuX6',
  prompt: 'select_account',
  authDomain: 'https://rider.zanride.com'
})
// appleProvider.setCustomParameters({
//   locale: 'en',
//   clientId: 'com.goParcel.GoParcel',
//   redirectUri: 'https://goparcel-1d992.firebaseapp.com/__/auth/handler'
// })

// appleProvider.setCustomParameters({
//   prompt: 'select_account '
// })

export const signInWithApplePopup = () =>
  signInWithPopup(auth, appleProvider)
    .then(result => {
      const user = result.user
      const credential = OAuthProvider.credentialFromResult(result)
      const apple_id = result._tokenResponse.federatedId
      const accessToken = credential.accessToken
      const idToken = credential.idToken
      return apple_id.split('/')[1]
    })
    .catch(error => {
      const errorCode = error.code
      const errorMessage = error.message
      const email = error.customData.email
      const credential = OAuthProvider.credentialFromError(error)
      console.log('catch', error)
    })
// export const signInWithApplePopup = () =>
//   reauthenticateWithPopup(auth.currentUser, appleProvider)
//     .then(result => {
//       const user = result.user
//       const credential = OAuthProvider.credentialFromResult(result)
//       const accessToken = credential.accessToken
//       const idToken = credential.idToken
//       console.log('result', credential)
//     })
//     .catch(error => {
//       const errorCode = error.code
//       const errorMessage = error.message
//       const email = error.customData.email
//       const credential = OAuthProvider.credentialFromError(error)
//       console.log('catch', error)
//     })

const facebookProvider = new FacebookAuthProvider()
export const signInWithFacebookPopup = () => signInWithPopup(auth, facebookProvider)
export const messaging = getMessaging(app)
// messaging
//   .requestPermission()
//   .then(() => {
//     return messaging.getToken()
//   })
//   .then(token => {
//     console.log('FCM Token:', token)
//   })
//   .catch(err => {
//     console.log('Error getting permission or token', err)
//   })

// Handle incoming messages

// const permission = await Notification.requestPermission()
// if (permission === 'granted') {
//   const token = await getToken(messaging, {
//     vapidKey: VAPID_KEY
//   })
//     .then(currentToken => {
//       if (currentToken) {
//         console.log('currentToken', currentToken)
//         localStorage.setItem('ZanFCMToken', currentToken)
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.')
//         // ...
//       }
//     })
//     .catch(err => {
//       console.log('An error occurred while retrieving token. ', err)
//       // ...
//     })
//   console.log('token', token)
// } else if (permission === 'denied') {
//   console.log('You denied for the notification')
// }

const InitializeFirebaseMessaging = async () => {
  try {
    await Notification.requestPermission()
      .then(() => {
        console.log('Notification permission granted.')
        const token = getToken(messaging, {
          vapidKey: VAPID_KEY
        }).catch(error => {
          console.log('error in getToken', error)
        })

        if (token) {
          console.log('Received FCM token:', token)
          localStorage.setItem('FCMToken', token)
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      })
      .catch(err => {
        console.error('Unable to get permission to notify.', err)
      })
  } catch (error) {
    console.error('Error occurred during Firebase Messaging initialization:', error)
  }
}
if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
  console.log('Notification in window condition')
  InitializeFirebaseMessaging()
} else {
  console.log('Firebase Messaging is not supported in this browser.')
}

onMessage(messaging, payload => {
  toast(<Message notification={payload?.notification} />)
})
